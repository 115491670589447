<template>
    <div class="grid px-4 py-14 md:grid-cols-2 md:px-0 lg:py-28">
        <div class="mb-[34px] flex flex-col justify-end">
            <div>
                <h2 class="mb-[30px]">
                    {{ data.heading }}
                </h2>
                <div
                    v-if="data.description"
                    class="wysiwyg-content"
                    v-html="data.description"
                ></div>
            </div>
        </div>
        <div class="order-last">
            <div class="grid w-full grid-cols-1 gap-x-4 gap-y-2 lg:grid-cols-2">
                <ProductPackagingCard
                    v-for="(card, key) in data.cards"
                    :key="key"
                    :card="card"
                    @mouseenter="addAnimation(card)"
                    @mouseleave="removeAnimation(card)"
                />
            </div>
            <div v-if="data.buttons" class="mt-[22px] gap-x-2 md:flex">
                <Button
                    v-for="(button, key) in data.buttons"
                    :key="key"
                    :button="button"
                />
            </div>
        </div>
        <div
            class="hidden md:row-span-2 md:row-start-1 md:block"
            :class="[data.reverseLayout ? 'md:col-start-2' : 'md:col-start-1']"
        >
            <div
                class="relative flex items-center justify-center p-28 md:h-full md:w-full md:p-12"
            >
                <img
                    v-for="(explosion, key) in explosionData"
                    :key="key"
                    :src="explosion.src"
                    alt="product explosion"
                    class="absolute z-0 w-full scale-0"
                    loading="lazy"
                    :class="[
                        explosion.animation ? 'bg-fade-grow' : 'bg-fade-shrink',
                    ]"
                />
                <StoryblokImage
                    v-if="bagImage"
                    :src="bagImage.src"
                    :alt="bagImage.alt"
                    class="z-10 h-auto w-auto object-contain md:h-[300px] lg:h-[428px]"
                    sizes="428px lg:640px"
                    :preload="false"
                    :class="[onHover ? 'grow-image' : 'shrink-image']"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Ref } from 'vue';
import ProductPackagingShowcaseType from '~/types/ProductPackagingShowcaseType';
import ProductPackagingShowcaseCardType from '~/types/ProductPackagingShowcaseCardType';
import ProductPackagingCard from './ProductPackagingCard.vue';
import Button from '~/components/page-building/components/Button.vue';

const props = defineProps<{
    data: ProductPackagingShowcaseType;
    explosionImages: object;
}>();

const onHover = ref(false);
const petFoodExplosion = ref(false);
const coffeeExplosion = ref(false);
const foodSnacksExplosion = ref(false);
const chocolateExplosion = ref(false);
const healthBeautyExplosion = ref(false);
const teaExplosion = ref(false);
const bagImage = ref(props.data.defaultImage);
interface explosion {
    src?: string;
    animation?: Ref;
}
const explosionData = ref<explosion[]>([]);
Object.entries(props.explosionImages).forEach(([key, value]) => {
    const obj: explosion = {};
    obj.src = value;
    if (key === 'pet-food') {
        obj.animation = petFoodExplosion;
    } else if (key === 'coffee-beans') {
        obj.animation = coffeeExplosion;
    } else if (key === 'food-snacks') {
        obj.animation = foodSnacksExplosion;
    } else if (key === 'chocolate') {
        obj.animation = chocolateExplosion;
    } else if (key === 'health-beauty') {
        obj.animation = healthBeautyExplosion;
    } else if (key === 'tea') {
        obj.animation = teaExplosion;
    }
    if (obj.src && obj.animation) {
        explosionData.value.push(obj);
    }
});

const addAnimation = (card: ProductPackagingShowcaseCardType) => {
    if (card.animationType === 'none') {
        bagImage.value = props.data.defaultImage;
    } else {
        bagImage.value = card.image;
        onHover.value = true;
    }
    if (card.animationType === 'pet-food') {
        petFoodExplosion.value = true;
    } else if (card.animationType === 'coffee-beans') {
        coffeeExplosion.value = true;
    } else if (card.animationType === 'food-snacks') {
        foodSnacksExplosion.value = true;
    } else if (card.animationType === 'chocolate') {
        chocolateExplosion.value = true;
    } else if (card.animationType === 'health-beauty') {
        healthBeautyExplosion.value = true;
    } else if (card.animationType === 'tea') {
        teaExplosion.value = true;
    }
};
const removeAnimation = (card: ProductPackagingShowcaseCardType) => {
    onHover.value = false;
    if (card.animationType === 'pet-food') {
        petFoodExplosion.value = false;
    } else if (card.animationType === 'coffee-beans') {
        coffeeExplosion.value = false;
    } else if (card.animationType === 'food-snacks') {
        foodSnacksExplosion.value = false;
    } else if (card.animationType === 'chocolate') {
        chocolateExplosion.value = false;
    } else if (card.animationType === 'health-beauty') {
        healthBeautyExplosion.value = false;
    } else if (card.animationType === 'tea') {
        teaExplosion.value = false;
    } else {
        bagImage.value = props.data.defaultImage;
    }
};
</script>

<style scoped>
.grow-image {
    transform: scale(1.2);
    animation: grow-image 0.4s ease;
}
.shrink-image {
    transform: scale(1);
    animation: shrink-image 0.5s ease;
}
.bg-fade-grow {
    opacity: 1;
    transform: scale(0.9);
    animation: grow-fade-bg 0.8s cubic-bezier(0.47, 1.4, 0.41, 1);
}
.bg-fade-shrink {
    opacity: 0;
    transform: scale(0);
    animation: shrink-fade-bg 0.5s ease-in-out;
}

@keyframes grow-image {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.2);
    }
}

@keyframes shrink-image {
    from {
        transform: scale(1.2);
    }

    to {
        transform: scale(1);
    }
}

@keyframes grow-fade-bg {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(0.9);
    }
}

@keyframes shrink-fade-bg {
    from {
        opacity: 1;
        transform: scale(0.9);
    }

    to {
        opacity: 0;
        transform: scale(0);
    }
}
</style>
