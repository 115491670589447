<template>
    <BlockContainer
        :id="'product-packaging-showcase-' + slugify(data.heading)"
        :background-color="data.backgroundColor"
        :bottom-angle="data.bottomAngle"
        :angle-background-color="
            data.nextBackgroundColor ? data.nextBackgroundColor : null
        "
        :arrow-callout="data.arrowCallout"
    >
        <template #container>
            <div class="container">
                <div class="hidden md:block">
                    <ProductPackagingShowcaseStandard
                        :data="data"
                        :explosion-images="explosionImages"
                    />
                </div>
            </div>
            <div class="md:hidden">
                <ProductPackagingShowcaseStandard
                    v-if="data.stackCardsOnMobile"
                    :data="data"
                    :explosion-images="explosionImages"
                />
                <ProductPackagingShowcaseMobile
                    v-else
                    :data="data"
                    :explosion-images="explosionImages"
                />
            </div>
        </template>
    </BlockContainer>
</template>

<script setup lang="ts">
import BlockContainer from '~/components/BlockContainer.vue';
import ProductPackagingShowcaseStandard from '~/components/page-building/product-packaging-showcase/components/ProductPackagingShowcaseStandard.vue';
import ProductPackagingShowcaseMobile from '~/components/page-building/product-packaging-showcase/components/ProductPackagingShowcaseMobile.vue';
import ProductPackagingShowcaseType from '~/types/ProductPackagingShowcaseType';

const explosionImages = ref({
    'pet-food': '/images/product-explosions/pet-food-explosion.webp',
    'coffee-beans': '/images/product-explosions/coffee-explosion.webp',
    'food-snacks': '/images/product-explosions/food-snacks-explosion.webp',
    chocolate: '/images/product-explosions/chocolate-explosion.webp',
    'health-beauty': '/images/product-explosions/health-beauty-explosion.webp',
    tea: '/images/product-explosions/tea-explosion.webp',
});

defineProps<{
    data: ProductPackagingShowcaseType;
}>();
</script>
