<template>
    <div class="relative pb-0 pt-16">
        <div
            ref="carouselContainer"
            class="swiper relative z-20 select-none pb-10 transition-opacity duration-1000"
            :class="{ 'opacity-0': !carouselLoaded }"
        >
            <div class="container mb-20 flex flex-col justify-end">
                <div>
                    <h2 class="mb-5 md:mb-[30px]">
                        {{ data.heading }}
                    </h2>
                    <div
                        v-if="data.description"
                        class="wysiwyg-content"
                        v-html="data.description"
                    ></div>
                </div>
            </div>
            <Swiper
                :slides-per-view="1"
                :centered-slides="true"
                :space-between="30"
                :watch-slides-progress="true"
                :lazy-preload-prev-next="3"
                :modules="[Pagination]"
                :pagination="true"
                @swiper="onSwiperInit"
                @slider-first-move="onSliderFirstMove"
                @transition-end="onTransitionEnd"
            >
                <SwiperSlide
                    v-for="(card, key) in data.cards"
                    :key="key"
                    class="flex h-full w-full cursor-grab justify-center"
                >
                    <div class="flex w-full flex-col">
                        <div
                            class="relative flex max-h-[400px] grow items-center justify-center"
                        >
                            <img
                                :src="explosionImages[card.animationType]"
                                alt="product explosion"
                                class="product-explosion absolute z-0 w-full scale-0"
                            />
                            <StoryblokImage
                                v-if="card.image"
                                :src="card.image.src"
                                :alt="card.image.alt"
                                sizes="366px"
                                :preload="false"
                                class="product-image z-10 max-h-[400px] w-auto scale-[1.1]"
                            />
                        </div>
                        <div
                            class="container relative z-10 mt-10 flex justify-center"
                        >
                            <ProductPackagingCard :card="card" />
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
        <div v-if="data.buttons" class="container mt-8 w-full">
            <Button
                v-for="(button, key) in data.buttons"
                :key="key"
                :button="button"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import ProductPackagingShowcaseType from '~/types/ProductPackagingShowcaseType';
import ProductPackagingCard from './ProductPackagingCard.vue';
import Button from '~/components/page-building/components/Button.vue';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css/pagination';

defineProps<{
    data: ProductPackagingShowcaseType;
    explosionImages: object;
}>();

const carouselLoaded = ref(false);

const onSwiperInit = (swiper) => {
    carouselLoaded.value = true;

    const { visibleSlides } = swiper;
    if (visibleSlides && visibleSlides[0]) {
        const explosion =
            visibleSlides[0].getElementsByClassName('product-explosion');
        explosion[0].classList.add('bg-fade-grow');
        explosion[0].classList.add('grow-image');
        const image = visibleSlides[0].getElementsByClassName('product-image');
        image[0].classList.add('shrink-image');
        image[0].classList.remove('grow-image');
    }
};

const onSliderFirstMove = (swiper, event) => {
    const { visibleSlides } = swiper;
    if (visibleSlides && visibleSlides[0]) {
        const explosion =
            visibleSlides[0].getElementsByClassName('product-explosion');
        explosion[0].classList.remove('bg-fade-grow');
        explosion[0].classList.add('bg-fade-shrink');
        const image = visibleSlides[0].getElementsByClassName('product-image');
        image[0].classList.remove('shrink-image');
        image[0].classList.add('grow-image');
    }
};

const onTransitionEnd = (swiper) => {
    const { visibleSlides } = swiper;
    if (visibleSlides && visibleSlides[0]) {
        const explosion =
            visibleSlides[0].getElementsByClassName('product-explosion');
        explosion[0].classList.remove('bg-fade-shrink');
        explosion[0].classList.add('bg-fade-grow');
        const image = visibleSlides[0].getElementsByClassName('product-image');
        image[0].classList.remove('grow-image');
        image[0].classList.add('shrink-image');
    }
};
</script>

<style scoped>
.grow-image {
    transform: scale(1.1);
    animation: grow-image 0.4s ease;
}
.shrink-image {
    transform: scale(1);
    animation: shrink-image 0.5s ease;
}
.bg-fade-grow {
    opacity: 1;
    transform: scale(1.1);
    animation: grow-fade-bg 0.8s cubic-bezier(0.47, 1.4, 0.41, 1);
}
.bg-fade-shrink {
    opacity: 0;
    transform: scale(0);
    animation: shrink-fade-bg 1s ease-in;
}

@keyframes grow-image {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.1);
    }
}

@keyframes shrink-image {
    from {
        transform: scale(1.1);
    }

    to {
        transform: scale(1);
    }
}

@keyframes grow-fade-bg {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(1.1);
    }
}

@keyframes shrink-fade-bg {
    from {
        opacity: 1;
        transform: scale(1.1);
    }

    to {
        opacity: 0;
        transform: scale(0);
    }
}
</style>
